import React, { useState } from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import styles from "../assets/css/snowgods.module.scss";
import { Link } from "gatsby-plugin-intl";
import { ReactSVG } from "react-svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Snowgod from "../components/Snowgod";
import { StyledSliderButton } from "../assets/styles/globalCommon";
import BannerTwoCol from "../components/Common/BannerTwoCol";

const snowGods = [
	{
		image: "/snowgods/ander-martinez.png",
		title: "Ander Martinez",
		link: { text: "Cantabria", url: "/snowgods" },
	},
	{
		image: "/snowgods/josep-deu-palacios.png",
		title: "Josep Deu Palacios",
		link: { text: "Cerdaña", url: "/snowgods" },
	},
	{
		image: "/snowgods/valentino-coton.png",
		title: "Valentino Coton",
		link: { text: "Andorra", url: "/snowgods" },
	},
	{
		image: "/snowgods/joan-cabrera.png",
		title: "Joan Cabrera",
		link: { text: "Vall d'Arán", url: "/snowgods" },
	},
	{
		image: "/snowgods/pamela-lustig.png",
		title: "Pamela Lustig",
		link: { text: "Pirineo Francés", url: "/snowgods" },
	},
	{
		image: "/snowgods/lenin-nunez-armas.png",
		title: "Lenin Nuñez",
		link: { text: "Aragón", url: "/snowgods" },
	},
	{
		image: "/snowgods/mariano-pelaez.png",
		title: "Mariano Peláez",
		link: { text: "Andorra", url: "/snowgods" },
	},
	{
		image: "/snowgods/jose-urbina.png",
		title: "Jose Urbina",
		link: { text: "Andorra", url: "/snowgods" },
	},
	{
		image: "/snowgods/pablo-saba.png",
		title: "Pablo Saba",
		link: { text: "Por del Comte", url: "/snowgods" },
	},
];

const SnowgodsPage = () => {
	const [swiperSnowgods, setSwiperSnowgods] = useState(null);

	return (
		<Layout>
			<Seo title="Snowgods" />
			<section className={styles.snowgods}>
				<article className={styles.bgSnowgods}>
					<div className={styles.snowgodsWrapper}>
						<Link to="/" className="lineLink white">
							<u>Volver a la home</u>
						</Link>
					</div>
					<div className={styles.titleWrapper}>
						<h2>Snowgods</h2>
					</div>
				</article>
				<article className={styles.snowgodsDetails}>
					<h3>Snowgods, fresh influencers</h3>
					<p>
						Contamos con un grupo de expertos en pie de pistas que forman
						parte de nuestra comunidad de usuarios pero que por su
						compromiso y profesionalidad, llamamos SNOWGODS.
						<br />
						<br />
						Ellos aportan información valiosa, en tiempo real y en el
						lugar de los hechos, nos ayudan a completar la información y a
						validar nuestras predicciones, en definitiva, nos ayudan a ser
						los mejores.
					</p>
					<br />
					<br />
					<StyledSliderButton id="kx8F8tJs" size="75">
						<p>Enviar solicitud</p>
					</StyledSliderButton>
				</article>
				<article className={styles.snowgodsSlider}>
					<div className={styles.snowgodsWrapper}>
						<div className={styles.left}>
							<h3>Nuestros Snowgods</h3>
							<p>
								Conoce quienes son y dónde están para estar atento a sus
								reportes.
							</p>
						</div>
						<div className={styles.right}>
							<Link className="lineLink white" to="/snowgods">
								<u>Ver todos los Snowgods</u>
							</Link>
							<div className={styles.sliderNavigation}>
								<button
									className={styles.arrowLeft}
									onClick={() => swiperSnowgods.slidePrev()}
								>
									<ReactSVG src="/img/white-arrow.svg" />
								</button>
								<button
									className={styles.arrowRight}
									onClick={() => swiperSnowgods.slideNext()}
								>
									<ReactSVG src="/img/white-arrow.svg" />
								</button>
							</div>
						</div>
						<div className={styles.slider}>
							<Swiper
								slidesPerView={2}
								spaceBetween={15}
								onSwiper={setSwiperSnowgods}
								breakpoints={{
									1025: {
										slidesPerView: 4,
										spaceBetween: 25,
									},
									769: {
										slidesPerView: 3,
										spaceBetween: 20,
									},
								}}
							>
								{snowGods.map((snowGod, index) => (
									<SwiperSlide key={index}>
										<Snowgod
											imagen={snowGod.image}
											nombre={snowGod.title}
											link={
												<Link
													className="lineLink ltblue"
													to={snowGod.link.url}
												>
													<u>{snowGod.link.text}</u>
												</Link>
											}
										/>
									</SwiperSlide>
								))}
							</Swiper>
						</div>
					</div>
				</article>

				<BannerTwoCol
					direction="right"
					layout="light"
					img="/snowgods/snowgods01.jpg"
					title="¿Quiéres formar parte de nuestro equipo de expertos?"
					text="Estamos constantemente seleccionando Snowgods para Fresh
								Snow. Si vives en alguna parte de nuestra área de
								cobertura, ponte en contacto con nosotros y envíanos tu
								perfil ¡No pierdas la oportunidad de formar parte de
								este equipo!"
				>
					<br />
					<StyledSliderButton id="kx8F8tJs" size="75">
						<p>Enviar solicitud</p>
					</StyledSliderButton>
				</BannerTwoCol>
			</section>
		</Layout>
	);
};

export default SnowgodsPage;
